<template>
    <div class="fluid">
        <div class="card">
            <h5>Row Editing</h5>
            <DataTable
                :value="products"
                editMode="cell"
                class="editable-cells-table"
                dataKey="anlage"
                v-model:editingRows="editingRows"
                @row-edit-save="onRowEditSave"
                responsiveLayout="stack"
                rowGroupMode="subheader"
                groupRowsBy="kategorie"
                sortMode="single"
                sortField="kategorie"
                :sortOrder="1"
            >
                <template #empty> Nichts gefunden </template>
                <Column field="kategorie" header="Kategorie"></Column>
                <Column field="anlage" header="Anlage" style="width: 8%; min-width: 6rem">
                </Column>
                <Column field="kategorie" header="Kategorie" style="width: 10%; min-width: 8rem"> </Column>
                <Column field="parameter" header="Parameter">
                </Column>
                <Column field="verhalten" header="Verhalten" style="width: 10%; min-width: 8rem" class="text-center"> </Column>
                <Column field="wert" header="Wert" style="width: 15%; min-width: 12rem" class="text-center">
                    <template #body="{ data, field }">
                        {{ data[field] + data.unit }}
                    </template>
                    <template #editor="{ data, field }">
                        <!-- <InputText v-model="data[field]" autofocus />{{ data.unit }} -->
                        <InputNumber
                            :id="data + field"
                            v-model="data[field]"
                            showButtons
                            buttonLayout="horizontal"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            mode="decimal"
                            :suffix="data.unit"
                            :step="0.5"
                            :min=0
                            :max=50
                            class="enat-middle"
                        />
                    </template>
                </Column>
                <template #groupheader="slotProps">
                    <template v-if="slotProps.data.kategorie === 'Heizung'">
                        <span class="icon"><span class="mif-fire"></span></span>
                    </template>
                    <template v-if="slotProps.data.kategorie === 'Lüftung'">
                        <span class="icon"><span class="mif-snowflake"></span></span>
                    </template>
                    <!-- <img
            :alt="slotProps.data.kategorie"
            src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
            width="32"
            style="vertical-align: middle"
          /> -->
                    <span class="image-text">{{ slotProps.data.kategorie }}</span>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
// import ProductService from "../helpers";
import mainService from '@/services/mainService.js';

export default {
    name: 'theTable3',
    data() {
        return {
            editingRows: [],
            columns: null,
            products: null,
            statuses: [
                { label: 'In Stock', value: 'INSTOCK' },
                { label: 'Low Stock', value: 'LOWSTOCK' },
                { label: 'Out of Stock', value: 'OUTOFSTOCK' },
            ],
        };
    },
    productService: null,
    created() {
        // this.productService = new ProductService();
    },
    methods: {
        getParameter() {
            mainService
                .getParameterList()
                .then((response) => {
                    // console.log('server: ', response.data)
                    this.products = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue)) data[field] = newValue;
                    else event.preventDefault();
                    break;

                default:
                    if (newValue.trim().length > 0) data[field] = newValue;
                    else event.preventDefault();
                    break;
            }
        },
        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, '') || '0';
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch (status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'LOWSTOCK':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },
    },
    mounted() {
        this.getParameter();
    },
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
::v-deep(.editable-cells-table td.p-cell-editing input) {
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 110%;
    color: yellow;
}
::v-deep(.editable-cells-table td.p-cell-editing .enat-middle input) {
    text-align: center;
}
::v-deep(.editable-cells-table td.p-editable-column .p-column-title) {
    padding-right: 1em;
}
::v-deep(.image-text) {
    font-weight: 700;
    color: yellow;
}
</style>
